import React from "react";
import styled from "@emotion/styled";
import { AspectRatio, Box, Text } from "theme-ui";
import Image from "next/image";
import Link from "next/link";
import getCountryEmoji from "util/getCountryEmoji";
import discordReport from "util/discordReport";

const AdImage = ({ imgSrc, link, name, price }) => {
  const handleClick = () => {
    try {
      const emojiFlag = getCountryEmoji();
      discordReport(
        `🔥 Ad is clicked (${name}) from ${emojiFlag}`,
        process.env.NEXT_PUBLIC_DISCORD_WH_AD_AF
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Link href={link} target="_blank">
      <Wrapper>
        <Box>
          <ImageWrapper onClick={handleClick}>
            <AspectRatio ratio={1}>
              {imgSrc && <Image src={imgSrc} fill />}
            </AspectRatio>
          </ImageWrapper>
          {name && <SmallText mt={1}>{name}</SmallText>}
        </Box>
        {price && <SmallText mt={1}>₱{price}</SmallText>}
      </Wrapper>
    </Link>
  );
};
export default AdImage;

const ImageWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.colors.grey[5]};
  &,
  * {
    border-radius: 10px;
  }
`;

const SmallText = styled(Text)`
  display: block;
  font-size: 0.65rem;
  line-height: 110%;
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;
