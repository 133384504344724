import React from "react";
import styled from "@emotion/styled";
import { Box, Text } from "theme-ui";
import AdImage from "./AdImage";
import SmallHorizontalAd from "../GoogleAds/SmallHorizontalAd";

const AdSpaceRouteItem = ({ ...rest }) => {
  return (
    <Container mb={3} {...rest}>
      <AdWapper mb={3}>
        <TextAd mb={1}>On Sale 🔥</TextAd>
        <Wrapper>
          <AdImage
            imgSrc="https://cf.shopee.ph/file/2ce63a67e01f555c7ba8524585f954b7"
            link="https://invl.io/clfwhh5"
            name={
              "Illuminated Note-Taking: The Glowing Notepad for Studious Students"
            }
            price={"185"}
          />
          <AdImage
            imgSrc="https://m.media-amazon.com/images/I/51L2WtS3w8L._AC_SX679_.jpg"
            link="https://invl.io/clfwhir"
            name={
              "Mini Printer: The Portable Solution for Personalized Note-Taking"
            }
            price={"599.00"}
          />
          <AdImage
            imgSrc="https://cf.shopee.ph/file/sg-11134201-22120-aft5grot1elv10"
            link="https://invl.io/clfwhjk"
            name={"Aquaflask Dream Collection II Limited Edition"}
            price={"890.00"}
          />
        </Wrapper>
      </AdWapper>
      <SmallHorizontalAd width={"100%"} height={90} mt={3} />
    </Container>
  );
};
export default AdSpaceRouteItem;

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
`;

const AdWapper = styled(Box)`
  width: 100%;
`;

const TextAd = styled(Text)`
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 0.8rem;
  display: block;
`;

const Container = styled(Box)``;
